<template>
  <div
    class="bg-accent-pink text-white rounded py-0.5 px-2 text-sm flex text-center whitespace-nowrap w-fit"
    :class="{
      '!text-primary-100': blue,
      '!text-primary-100 !bg-primary-50': blueBg,
      '!bg-success-100 !font-medium': greenBg,
      '!bg-neutral-50 !text-black': grey,
      'border border-accent-pink !text-accent-pink !bg-accent-lightpink':
        reversed && !grey && !blue,
      'border border-primary-100  !bg-primary-50': reversed && blue,
      'border border-neutral-80 !text-neutral-200 !bg-white': reversed && grey
    }"
  >
    <template v-if="medium">
      <TextRegular
        class="text-white"
        :class="{
          '!text-primary-100': blue,
          '!text-primary-100': blueBg,
          greenBg,
          '!text-black': grey,
          '!text-accent-pink': reversed,
          '!text-neutral-200': reversed && grey
        }"
      >
        <span>
          {{ text }}
          <span
            v-if="Object.values(slots).length > 0"
            class="icon ml-2 text-white"
            :class="{
              '!text-primary-100': blue,
              '!text-primary-100': blueBg,
              greenBg,
              '!text-black': grey,
              '!text-accent-pink': reversed,
              '!text-neutral-200': reversed && grey
            }"
          >
            <slot />
          </span>
        </span>
      </TextRegular>
    </template>
    <template v-else>
      <small>
        {{ text }}
        <span v-if="Object.values(slots).length > 0" class="icon ml-2">
          <slot />
        </span>
      </small>
    </template>
  </div>
</template>
<script setup>
import TextRegular from '@/components/shared/font/text/TextRegular.vue'
import { useSlots } from 'vue'
const slots = useSlots()

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  medium: Boolean,
  blue: Boolean,
  grey: Boolean,
  reversed: Boolean,
  blueBg: Boolean,
  greenBg: Boolean
})
</script>
